import { useApolloClient, useMutation } from '@apollo/client'
import { useCart } from 'components/Cart'
import ShippingDetails, { isShippablePostcode, shippingDetailsSchema } from 'components/Cart/ShippingDetails'
import GenericServerError from 'components/GenericServerError'
import SubmitButton from 'components/SubmitButton'
import { Form, Formik } from 'formik'
import { CheckoutProps, placeOrderMutation } from './Checkout'
import { PlaceOrderMutation, PlaceOrderMutationVariables } from './__generated__/PlaceOrderMutation'

const validationSchema = shippingDetailsSchema

const initialValues = {
  shippingAddress1: '',
  shippingAddress2: '',
  shippingTown: '',
  shippingPostcode: '',
}

const NoPayCheckout = ({
  orderRequest,
  email,
  firstName,
  lastName,
  arrivalDate,
  vaccinationStatus,
  appointmentId,
  onSuccess,
}: CheckoutProps) => {
  const cart = useCart()
  const [placeOrder, { error }] = useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(placeOrderMutation)
  const client = useApolloClient()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setFieldError }) => {
        const postcodeDetails = await isShippablePostcode(client, values.shippingPostcode)

        if (postcodeDetails.error) {
          setFieldError('shippingPostcode', postcodeDetails.error)
          return
        }

        const result = await placeOrder({
          variables: {
            input: {
              confirm: true,

              orderLines: orderRequest.orderLines,
              shippingMethodId: orderRequest.shippingMethodId,
              discountCode: orderRequest.discountCode,

              email,
              firstName,
              lastName,
              arrivalDate,
              vaccinationStatus,
              shippingAddress: {
                line1: values.shippingAddress1,
                line2: values.shippingAddress2,
                town: values.shippingTown,
                postcode: values.shippingPostcode,
              },
              appointmentId,
              forceVisionFulfilment: false,
            },
          },
        })

        if (result.error) {
          throw new Error('Failed to place order')
        }

        await onSuccess(result.data.placeOrder)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <h4 className="subtitle is-size-5">{cart.onlySupervisions ? 'Address' : 'Delivery'} information</h4>

          <ShippingDetails />

          <hr />

          <h4 className="subtitle is-size-5">Payment information</h4>
          <p>No payment required</p>

          {error && <GenericServerError />}

          <div className="buttons is-right mt-5 mb-0">
            <SubmitButton className="is-midnightBlue" submitting={isSubmitting}>
              Confirm Order
            </SubmitButton>
          </div>
          <p className="is-size-7 has-text-right mt-0">
            By confirming this order you agree to abide by our{' '}
            <a target="_blank" href="/privacy-policy/">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a target="_blank" href="/terms-of-service">
              Terms of Service
            </a>
            .
          </p>
        </Form>
      )}
    </Formik>
  )
}
export default NoPayCheckout

import { gql, useQuery } from '@apollo/client'
import { formatDateOnly } from 'helpers/string'
import { groupBy } from 'lodash'
import { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useCart } from '../Cart'
import {
  BookableAppointmentsQuery,
  BookableAppointmentsQueryVariables,
  BookableAppointmentsQuery_bookableAppointments,
} from './__generated__/BookableAppointmentsQuery'

const bookableAppointmentsQuery = gql`
  query BookableAppointmentsQuery($onlySupervisions: Boolean) {
    bookableAppointments(onlySupervisions: $onlySupervisions) {
      id
      date
      startHour
      startMinute
    }
  }
`

interface AppointmentStateProps {
  appointmentId: string
  setAppointmentId: React.Dispatch<React.SetStateAction<string>>
}

const AppointmentTimeSelector = ({
  appointments,
  appointmentId,
  setAppointmentId,
}: {
  appointments: BookableAppointmentsQuery_bookableAppointments[]
} & AppointmentStateProps) => {
  const initialAppointment = appointmentId ? appointments.find((x) => x.id === appointmentId) : appointments[0]

  const [selectedDate, setSelectedDate] = useState(initialAppointment.date)

  useEffect(() => {
    if (!appointmentId) {
      setAppointmentId(appointments[0].id)
    }
  }, [appointmentId, appointments, setAppointmentId])

  const days = groupBy(appointments, (appt) => appt.date)

  return (
    <div>
      <div className="select">
        <select
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e.target.value)
            const firstAppointment = appointments.find((x) => x.date === e.target.value)

            // There will(!) always be an appointment available
            // otherwise we would not have shown the date at all
            setAppointmentId(firstAppointment.id)
          }}
        >
          {Object.keys(days).map((day) => (
            <option key={day} value={day}>
              {formatDateOnly(day)}
            </option>
          ))}
        </select>
      </div>

      <div className="select">
        <select value={appointmentId} onChange={(e) => setAppointmentId(e.target.value)}>
          {days[selectedDate].map((appt) => (
            <option key={appt.id} value={appt.id}>
              {appt.startHour.toString().padStart(2, '0')}:{appt.startMinute.toString().padStart(2, '0')}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

const AppointmentTimes = ({ appointmentId, setAppointmentId }: AppointmentStateProps) => {
  const { onlySupervisions } = useCart()

  const { loading, error, data } = useQuery<BookableAppointmentsQuery, BookableAppointmentsQueryVariables>(
    bookableAppointmentsQuery,
    {
      variables: {
        onlySupervisions,
      },
    }
  )

  if (loading) {
    return <p>Loading appointment times...</p>
  }

  if (error) {
    return <p>Error loading appointment times</p>
  }

  return (
    <AppointmentTimeSelector
      appointments={data.bookableAppointments}
      appointmentId={appointmentId}
      setAppointmentId={setAppointmentId}
    />
  )
}

interface AppointmentChooserProps {
  initialAppointmentId: string
  onSubmit: (appointmentId: string) => void
  onGoBack: () => void
}

const AppointmentChooser = ({ initialAppointmentId, onSubmit, onGoBack }: AppointmentChooserProps) => {
  const [appointmentId, setAppointmentId] = useState(initialAppointmentId)

  return (
    <div>
      <h4 className="subtitle is-size-5">Appointment Time</h4>

      <p>Choose a time at which to attend the video supervision for your test:</p>

      <AppointmentTimes appointmentId={appointmentId} setAppointmentId={setAppointmentId} />

      <div className="is-flex is-fullwidth is-justify-content-space-between is-align-items-center mt-3">
        <button className="button is-text is-size-7" onClick={onGoBack} type="button">
          <div className="is-flex is-align-items-center">
            <FaArrowLeft className="mr-2" /> Back
          </div>
        </button>

        <button type="submit" className="button is-midnightBlue" onClick={() => onSubmit(appointmentId)}>
          Next Step: Your Details
        </button>
      </div>
    </div>
  )
}

export default AppointmentChooser

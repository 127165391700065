import { ApolloClient, gql, useApolloClient } from '@apollo/client'
import cx from 'classnames'
import { BulmaMessage } from 'components/Bulma'
import TextInput from 'components/TextInput'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import {
  PostcodeSearchQuery,
  PostcodeSearchQueryVariables,
  PostcodeSearchQuery_postcodeSearch,
} from './__generated__/PostcodeSearchQuery'

export const shippingDetailsSchema = yup.object().shape({
  shippingAddress1: yup.string().label('Shipping address line 1').required(),
  shippingAddress2: yup.string(),
  shippingTown: yup.string().label('Shipping town').required(),
  shippingPostcode: yup.string().label('Shipping postcode').required(),
})

export interface ShippingFormValues {
  shippingAddress1: string
  shippingAddress2: string
  shippingTown: string
  shippingPostcode: string
}

const postcodeSearchQuery = gql`
  query PostcodeSearchQuery($input: PostcodeSearchInput!) {
    postcodeSearch(input: $input) {
      postcode
      isRestricted
      isValidPostcode
      addresses {
        id
        text
        line1
        line2
        town
      }
    }
  }
`

interface Address {
  line1: string
  line2: string
  town: string
  postcode: string
}

interface PostcodeSearchFormProps {
  onRequestManualInput: (message?: string) => void
  onPostcodeSelected: (address: Address) => void
}

export const isShippablePostcode = async (client: ApolloClient<any>, postcode: string) => {
  try {
    const resp = await client.query<PostcodeSearchQuery, PostcodeSearchQueryVariables>({
      query: postcodeSearchQuery,
      variables: { input: { postcode } },
    })

    if (resp?.data?.postcodeSearch?.isRestricted) {
      return {
        valid: false,
        error: `We cannot ship to the Scottish Highlands & Islands, Northern Ireland, or the Channel Islands`,
      }
    }

    if (resp?.data?.postcodeSearch?.isValidPostcode === false) {
      return { valid: false, error: 'We only ship to valid UK postcodes' }
    }

    return { valid: true }
  } catch (err) {
    return { valid: true }
  }
}

const PostcodeSearchForm = ({ onRequestManualInput, onPostcodeSelected }: PostcodeSearchFormProps) => {
  const client = useApolloClient()
  const [postcode, setPostcode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<PostcodeSearchQuery_postcodeSearch>(null)

  const onSearch = async () => {
    if (postcode.length === 0) {
      return
    }

    try {
      setIsLoading(true)
      const resp = await client.query<PostcodeSearchQuery, PostcodeSearchQueryVariables>({
        query: postcodeSearchQuery,
        variables: { input: { postcode } },
      })

      setResults(resp.data.postcodeSearch)
    } catch (err) {
      onRequestManualInput('Postcode search unavailable, please enter address manually')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {!results && (
        <>
          <div className="is-flex-tablet">
            <div className="field">
              <label htmlFor="postcode-search" className="label is-size-7 has-text-weight-light has-text-left">
                Enter your postcode
              </label>

              <div className="field has-addons">
                <div className="control">
                  <input
                    name="postcode-search"
                    className="input"
                    type="text"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation()
                        onSearch()
                      }
                    }}
                  />
                </div>
                <div className="control">
                  <button
                    className={cx('button is-primary', {
                      'is-loading': isLoading,
                    })}
                    onClick={onSearch}
                    type="button"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="buttons is-right">
              <button className="button is-text is-small mt-2" onClick={() => onRequestManualInput()} type="button">
                Manually enter address
              </button>
            </div>
          </div>
        </>
      )}

      {results && (
        <>
          {results.addresses.length > 0 && <div>Choose your address:</div>}

          <div className={cx({ 'postcode-list': results.addresses.length > 0 })}>
            {results.isRestricted && (
              <p>We cannot ship to the Scottish Highlands & Islands, Northern Ireland, or the Channel Islands</p>
            )}

            {!results.isRestricted && results.addresses.length === 0 && (
              <p>
                No results for &ldquo;{results.postcode}&rdquo;. Try another postcode or enter your address manually.
              </p>
            )}

            {!results.isRestricted && results.addresses.length > 0 && (
              <ul>
                {results.addresses.map((address) => (
                  <li key={address.id}>
                    <button
                      type="button"
                      className="button is-text"
                      onClick={() =>
                        onPostcodeSelected({
                          line1: address.line1,
                          line2: address.line2,
                          town: address.town,
                          postcode: results.postcode,
                        })
                      }
                    >
                      {address.text}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="buttons is-right">
            {' '}
            <button type="button" onClick={() => setResults(null)} className="button is-text is-small mt-2">
              Search again
            </button>
            <button type="button" className="button is-text is-small mt-2" onClick={() => onRequestManualInput()}>
              Manually enter address
            </button>
          </div>
        </>
      )}
    </>
  )
}

const ManualShippingDetailsForm = () => {
  return (
    <>
      <TextInput name="shippingAddress1" label="Shipping Address" placeholder="Address Line 1" type="text" />
      <TextInput name="shippingAddress2" placeholder="Address Line 2" type="text" />
      <TextInput name="shippingTown" label="Shipping Town" type="text" />
      <TextInput name="shippingPostcode" label="Shipping Postcode" type="text" />
    </>
  )
}

const ShippingDetails = () => {
  const [isManual, setIsManual] = useState(false)
  const [message, setMessage] = useState<string>()
  const formik = useFormikContext<ShippingFormValues>()

  return (
    <>
      {isManual && (
        <>
          {message && <BulmaMessage color="warning">{message}</BulmaMessage>}
          <ManualShippingDetailsForm />

          <div className="buttons is-right">
            <button
              className="button is-text is-small mt-2"
              onClick={() => {
                setIsManual(false)
                setMessage(undefined)
              }}
              type="button"
            >
              Search by postcode
            </button>
          </div>
        </>
      )}

      {!isManual && (
        <>
          <PostcodeSearchForm
            onRequestManualInput={(message) => {
              setIsManual(true)
              setMessage(message)
            }}
            onPostcodeSelected={(address) => {
              formik.setFieldValue('shippingAddress1', address.line1, false)
              formik.setFieldValue('shippingAddress2', address.line2, false)
              formik.setFieldValue('shippingTown', address.town, false)
              formik.setFieldValue('shippingPostcode', address.postcode, false)
              setIsManual(true)
              setMessage(undefined)
            }}
          />
        </>
      )}
    </>
  )
}

export default ShippingDetails

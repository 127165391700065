import { useCart } from 'components/Cart'
import { Form, Formik } from 'formik'
import * as React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import * as yup from 'yup'
import SubmitButton from '../components/SubmitButton'
import TextInput from '../components/TextInput'

export interface OrderDetailsFormValues {
  firstName: string
  lastName: string
  email: string
  emailConfirm: string
  // Required for Day 2/8 tests
  arrivalDate?: string
  vaccinationStatus?: string
}

const validationSchema = yup.object().shape({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  email: yup.string().label('Email').email().required(),
  emailConfirm: yup
    .string()
    .label('Confirm Email')
    .email()
    .test('matches-email', 'Both email inputs must match', (value, { parent }) => {
      return value?.trim().toUpperCase() === parent.email?.trim().toUpperCase()
    }),
})

interface OrderDetailsFormProps {
  onCompletion: (values: OrderDetailsFormValues) => void
  onGoBack: () => void
}

const OrderDetailsForm = ({ onCompletion, onGoBack }: OrderDetailsFormProps) => {
  const initialValues: OrderDetailsFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirm: '',
    vaccinationStatus: null,
    arrivalDate: '',
  }

  const cart = useCart()

  return (
    <Formik<OrderDetailsFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        onCompletion({
          ...values,
        })
      }}
    >
      {({ values, isValid }) => (
        <Form>
          <h4 className="subtitle is-size-5">{cart.onlySupervisions ? 'Order' : 'Delivery'} information</h4>

          <TextInput name="firstName" label="First Name" type="text" />

          <TextInput name="lastName" label="Last Name" type="text" />

          <TextInput name="email" type="email" label="Email Address" />
          <TextInput name="emailConfirm" type="email" label="Confirm Email Address" />

          <div className="is-size-7 has-text-weight-light has-text-right mb-1 pr-1 mt-5">
            {values.email && isValid && (
              <>
                Your confirmation email will be sent to:{' '}
                <span className="has-text-primary has-text-weight-normal">{values.email}</span>
              </>
            )}
          </div>

          <div className="is-flex is-justify-content-space-between is-align-items-center">
            <button className="button is-text is-size-7" onClick={onGoBack} type="button">
              <div className="is-flex is-align-items-center">
                <FaArrowLeft className="mr-2" /> Back
              </div>
            </button>

            <SubmitButton className="is-midnightBlue">
              Next step: {!cart.onlySupervisions && 'Shipping &'} Payment
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OrderDetailsForm

import BackendMiniCart from 'components/BackendMiniCart'
import { useCart } from 'components/Cart'
import Checkout from 'components/Checkout'
import DeliveryEstimate from 'components/DeliveryEstimate'
import NoPayCheckout from 'components/NoPayCheckout'
import OrderDetailsForm, { OrderDetailsFormValues } from 'components/OrderDetailsForm'
import AppointmentChooser from 'components/Video/AppointmentChooser'
import { PlaceOrderMutation_placeOrder } from 'components/__generated__/PlaceOrderMutation'
import { navigate } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'

enum FormSteps {
  PATIENT_DETAILS,
  PAYMENT,
  CHOOSE_SUPERVISION_APPOINTMENT,
}

interface FormState {
  currentStep: FormSteps
  orderId?: string
  patientDetails?: OrderDetailsFormValues
  appointmentId?: string
}

const CheckoutPage = () => {
  const cart = useCart()

  const initialState = {
    currentStep: cart.hasSupervisions ? FormSteps.CHOOSE_SUPERVISION_APPOINTMENT : FormSteps.PATIENT_DETAILS,
  }

  const [state, setState] = useState<FormState>(initialState)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.currentStep])

  if (cart.checkoutError && state.currentStep === FormSteps.PATIENT_DETAILS) {
    // navigate('/basket')
    return null
  }

  const backendMiniCart = cart.calculated && (
    <BackendMiniCart
      loading={cart.loading}
      orderPriceDetails={cart.calculated.calculateOrderPrice}
      shippingMethods={cart.calculated.shippingMethods}
      discountCode={cart.cart.discountCode}
      readOnly
      appointmentId={state.appointmentId}
    />
  )

  const onSuccess = async (orderContent: PlaceOrderMutation_placeOrder) => {
    cart.setOrderResponse(orderContent)
    cart.clearCart() // empty cart
    setState({ ...initialState }) // clear checkout
    await navigate('/order-confirm')
  }

  const checkoutProps = {
    orderRequest: cart.orderRequest,
    email: state.patientDetails?.email,
    firstName: state.patientDetails?.firstName,
    lastName: state.patientDetails?.lastName,
    arrivalDate: state.patientDetails?.arrivalDate,
    vaccinationStatus: state.patientDetails?.vaccinationStatus,
    appointmentId: state.appointmentId,
    onSuccess: onSuccess,
  }

  return (
    <>
      <GatsbySeo title="Order" />

      <section className="section pt-1">
        <div className="container">
          <div className="columns has-columns-reversed">
            <div className="column pl-5">
              <div className="is-hidden-mobile">
                <h4 className="subtitle is-size-5">Order details</h4>
                {backendMiniCart}

                {cart.calculated?.calculateOrderPrice && !cart.onlySupervisions && (
                  <div className="mt-2 px-3 py-2 has-background-info-light">
                    <DeliveryEstimate deliveryEstimate={cart.calculated.calculateOrderPrice.deliveryEstimate} />
                  </div>
                )}
              </div>
            </div>

            <div className="column is-three-fifths">
              {state.currentStep === FormSteps.CHOOSE_SUPERVISION_APPOINTMENT && (
                <div>
                  <AppointmentChooser
                    initialAppointmentId={state.appointmentId}
                    onSubmit={(appointmentId) =>
                      setState((prev) => ({
                        ...prev,
                        currentStep: FormSteps.PATIENT_DETAILS,
                        appointmentId,
                      }))
                    }
                    onGoBack={() => navigate('/basket')}
                  />
                </div>
              )}

              {state.currentStep === FormSteps.PATIENT_DETAILS && (
                <OrderDetailsForm
                  onCompletion={(patientDetails) =>
                    setState((state) => ({
                      ...state,
                      currentStep: FormSteps.PAYMENT,
                      patientDetails,
                    }))
                  }
                  onGoBack={() => {
                    if (cart.hasSupervisions) {
                      setState((prev) => ({
                        ...prev,
                        currentStep: FormSteps.CHOOSE_SUPERVISION_APPOINTMENT,
                      }))
                    } else {
                      navigate('/basket')
                    }
                  }}
                />
              )}

              {state.currentStep === FormSteps.PAYMENT && cart.calculated?.calculateOrderPrice.total > 0 && (
                <Checkout {...checkoutProps} />
              )}

              {state.currentStep === FormSteps.PAYMENT && cart.calculated?.calculateOrderPrice.total === 0 && (
                <NoPayCheckout {...checkoutProps} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CheckoutPage

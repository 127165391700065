import { gql, useApolloClient, useMutation } from '@apollo/client'
import { CardElement, StripeCardElementChangeEvent, useElements, useStripe } from '@stripe/react-stripe-js'
import { ConfirmCardPaymentData } from '@stripe/stripe-js'
import { useCart } from 'components/Cart'
import ShippingDetails, { isShippablePostcode, shippingDetailsSchema } from 'components/Cart/ShippingDetails'
import GenericServerError from 'components/GenericServerError'
import SubmitButton from 'components/SubmitButton'
import TextInput from 'components/TextInput'
import { Field, Form, Formik } from 'formik'
import { trackFbEvent } from 'helpers/fb-pixel'
import stripeBadge from 'images/powered_by_stripe.svg'
import { CalculateOrderPriceVariables } from 'pages/__generated__/CalculateOrderPrice'
import { useState } from 'react'
import * as yup from 'yup'
import {
  PlaceOrderMutation,
  PlaceOrderMutationVariables,
  PlaceOrderMutation_placeOrder,
} from './__generated__/PlaceOrderMutation'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: '"Fira Sans", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      color: '#363636',
    },
    invalid: {
      color: '#363636',
      iconColor: '#f14668',
    },
  },
}

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().label('Cardholder Name').required(),
    shipToBilling: yup.boolean(),
    billingAddress1: yup.string().when('shipToBilling', {
      is: false,
      then: yup.string().required('Billing address line 1 is required if not shipping to the billing address'),
    }),
    billingAddress2: yup.string(),
    billingTown: yup.string().when('shipToBilling', {
      is: false,
      then: yup.string().required('Billing town is required if not billing to the shipping address'),
    }),
    billingPostcode: yup.string().when('shipToBilling', {
      is: false,
      then: yup.string().required('Billing postcode is required if not shipping to the billing address'),
    }),
  })
  .concat(shippingDetailsSchema)

const initialValues = {
  name: '',
  email: '',
  billingAddress1: '',
  billingAddress2: '',
  billingTown: '',
  billingPostcode: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingTown: '',
  shippingPostcode: '',
  shipToBilling: true,
}

export const placeOrderMutation = gql`
  mutation PlaceOrderMutation($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      success
      orderId
      clientSecret
      orderPriceDetails {
        total
        orderLines {
          day2and8ref
          firstName
          lastName
        }
      }
    }
  }
`

export interface CheckoutProps {
  orderRequest: CalculateOrderPriceVariables
  email: string
  firstName: string
  lastName: string
  arrivalDate?: string
  vaccinationStatus?: string
  appointmentId?: string
  onSuccess: (orderContent: PlaceOrderMutation_placeOrder) => Promise<void>
}

const Checkout = ({
  orderRequest,
  email,
  firstName,
  lastName,
  arrivalDate,
  vaccinationStatus,
  appointmentId,
  onSuccess,
}: CheckoutProps) => {
  const cart = useCart()

  const [cardDetailsState, setCardDetailsState] = useState<StripeCardElementChangeEvent>({})
  const [placeOrder, { error }] = useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(placeOrderMutation)

  const stripe = useStripe()
  const elements = useElements()
  const client = useApolloClient()

  const onSubmit = async (values, { setFieldError }) => {
    const postcodeDetails = await isShippablePostcode(client, values.shippingPostcode)

    if (postcodeDetails.error) {
      setFieldError('shippingPostcode', postcodeDetails.error)
      return
    }

    const orderDetails = await placeOrder({
      variables: {
        input: {
          confirm: false,

          orderLines: orderRequest.orderLines,
          shippingMethodId: orderRequest.shippingMethodId,
          discountCode: orderRequest.discountCode,

          email,
          firstName,
          lastName,
          arrivalDate,
          vaccinationStatus,
          appointmentId,
          forceVisionFulfilment: false,
        },
      },
    })

    const { clientSecret, orderPriceDetails } = orderDetails.data?.placeOrder

    if (!clientSecret) {
      throw new Error('No client secret from server, cannot initiate payment')
    }

    const stripeParams: ConfirmCardPaymentData = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: values.name,
          address: {
            postal_code: values.shipToBilling ? values.shippingPostcode : values.billingPostcode,
          },
        },
      },
      shipping: {
        name: `${firstName} ${lastName}`,
        address: {
          line1: values.shippingAddress1,
          line2: values.shippingAddress2,
          city: values.shippingTown,
          postal_code: values.shippingPostcode,
        },
      },
    }

    const result = await stripe.confirmCardPayment(clientSecret, stripeParams)

    if (result.error) {
      // Stripe logs suggest that we occasionally try to confirm a payment intent twice, which we believe leads to the user seeing an error and retrying the payment even though it did actually succeed.
      // There seems to be no code path that would allow that, but handle the possible second-confirm outcome here so at least the customer does not attempt to retry
      const paymentIntentAlreadySucceeded =
        result.error.code === 'payment_intent_unexpected_state' && result.error.payment_intent.status === 'succeeded'

      if (!paymentIntentAlreadySucceeded) {
        setCardDetailsState({ error: result.error, complete: true })
        return
      }
    }

    trackFbEvent('Purchase', {
      currency: 'GBP',
      value: orderPriceDetails.total / 100,
    })

    await onSuccess(orderDetails.data.placeOrder)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ submitCount, values, isSubmitting }) => {
        const cardIncompleteError = submitCount > 0 && !cardDetailsState.complete && 'Card details must be completed'

        const cardError = cardDetailsState.error?.message || cardIncompleteError

        return (
          <Form>
            <h4 className="subtitle is-size-5">
              {cart.onlySupervisions ? 'Address' : 'Delivery'} information (UK only)
            </h4>

            <ShippingDetails />

            <hr />

            <h4 className="subtitle is-size-5">Payment information</h4>

            <TextInput name="name" label="Cardholder Name" type="text" />

            <div className="field">
              <label
                className="label is-flex is-size-7 has-text-weight-light"
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Card details <img src={stripeBadge} alt="Powered by Stripe" />
              </label>
              <div className="control is-expanded">
                <CardElement
                  id="card-element"
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setCardDetailsState(e)
                  }}
                />

                {cardError && <p className="help is-danger">{cardError}</p>}
              </div>
            </div>

            {cart.onlySupervisions ? (
              <input name="shipToBilling" type="hidden" value="true" />
            ) : (
              <label className="checkbox">
                <Field name="shipToBilling" type="checkbox" /> Billing address same as shipping address
              </label>
            )}

            {values.shipToBilling ? null : (
              <>
                <TextInput name="billingAddress1" label="Billing Address" placeholder="Address Line 1" type="text" />
                <TextInput name="billingAddress2" placeholder="Address Line 2" type="text" />
                <TextInput name="billingTown" label="Billing Town" type="text" />
                <TextInput name="billingPostcode" label="Billing Postcode" type="text" />
              </>
            )}

            {error && <GenericServerError />}

            <div className="buttons is-right mt-5 mb-0">
              <SubmitButton
                className="is-midnightBlue"
                submitting={isSubmitting}
                disabled={isSubmitting || !stripe || !elements}
              >
                Place Order
              </SubmitButton>
            </div>
            <p className="is-size-7 has-text-right mt-0">
              By confirming this order you agree to abide by our{' '}
              <a target="_blank" href="/privacy-policy/">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a target="_blank" href="/terms-of-service">
                Terms of Service
              </a>
              .
            </p>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Checkout
